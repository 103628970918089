function extractAnalyses() {
  var tempAnalyses = [];
  this.OUTSAMPLES.forEach((sample) => {
    Array.prototype.push.apply(tempAnalyses, sample.sample_analyses);
  });
  return tempAnalyses;
}


function addPreAnalyticPrice(tempAnalyses) {
  this.nabmTable.push({
    analysis_id: "B17",
    analysis_b_price: "B17",
    analysis_name: "B17",
    analysis_nabm: "9005",
    analysis_100: false
  });
  this.record.record_nabms.push("9005");
  this.record.record_cotations.push("B17");

  // ADD SATURDAY AFTERNOON COTATION
  var today = new Date();
  if (
    today.getDay() == 0 ||
    (today.getDay() == 6 && today.getHours() >= 12)
  ) {
    this.nabmTable.push({
      analysis_id: "B26",
      analysis_b_price: "B26",
      analysis_name: "B26",
      analysis_nabm: "9004",
      analysis_100: false
    });
    this.record.record_nabms.push("9004");
    this.record.record_cotations.push("B26");
  }

  if (localStorage.getItem("default_clinic") == "true") {
    this.nabmTable.push({
      analysis_id: "B8",
      analysis_b_price: "B8",
      analysis_name: "B8",
      analysis_nabm: "9107",
      analysis_100: false
    });
    this.record.record_nabms.push("9107");
    this.record.record_cotations.push("B8");
  }

  var addedB9 = false;
  for (var i = 0; i < Object.keys(this.analysesData).length; ++i) {
    let tmpAnalysis =
      this.analysesData[Object.keys(this.analysesData)[i]];
    var isPresent = false;
    if (tempAnalyses.includes(tmpAnalysis.analysis_id)) {
      this.nabmTable.push(tmpAnalysis);
      this.nabmTableAnalyses.push(tmpAnalysis);
      isPresent = true;
    } else if (tempAnalyses.includes(String(tmpAnalysis.analysis_id))) {
      this.nabmTable.push(tmpAnalysis);
      this.nabmTableAnalyses.push(tmpAnalysis);
      isPresent = true;
    }

    if (isPresent) {
      if (
        tmpAnalysis.analysis_name.includes("MICROBIOLOGIE") &&
        !addedB9
      ) {
        this.nabmTable.push({
          analysis_id: "B9",
          analysis_b_price: "B9",
          analysis_name: "B9",
          analysis_nabm: "9106",
          analysis_100: false
        });
        this.record.record_nabms.push("9106");
        this.record.record_cotations.push("B9");
        addedB9 = true;
      }
    }
  }

  if (!addedB9) {
    // PCR ANALYSIS
    if (
      this.OUTSAMPLES[0].sample_analyses[0] == 1 ||
      this.OUTSAMPLES[0].sample_analyses[0] == 2
    ) {
      this.nabmTable.push({
        analysis_id: "B4",
        analysis_b_price: "B4",
        analysis_name: "B4",
        analysis_nabm: "9006",
        analysis_100: false
      });
      this.record.record_nabms.push("9006");
      this.record.record_cotations.push("B4");
    }

    // OTHER ANALYSIS
    else {
      this.nabmTable.push({
        analysis_id: "B5",
        analysis_b_price: "B5",
        analysis_name: "B5",
        analysis_nabm: "9105",
        analysis_100: false
      });
      this.record.record_nabms.push("9105");
      this.record.record_cotations.push("B5");
    }
  }
}


function extractNabmsCotations() {
  var cotations = [];
  this.nabmList = [];
  this.nabmTable.forEach((nabmEntry) => {
    cotations = cotations.concat(nabmEntry.analysis_b_price.split(";"));
    this.nabmList = this.nabmList.concat(
      nabmEntry.analysis_nabm.split(";")
    );
    if (nabmEntry.analysis_100 == true) {
      this.nabmList100 = this.nabmList100.concat(
        nabmEntry.analysis_nabm.split(";")
      );
    }
    else {
      this.nabmList60 = this.nabmList60.concat(
        nabmEntry.analysis_nabm.split(";")
      );
    }
  });

  for (var n = this.nabmList.length - 1; 0 <= n; --n) {
    this.nabmList[n] = this.nabmList[n].trim();
    this.nabmList[n] = this.nabmList[n].replaceAll("-", "");
    if (this.nabmList[n].length < 2) this.nabmList.splice(n, 1);
  }
  return cotations;
}


function computeTotalAnalyticalPrice(cotations) {
  var totalCotations = 0;
  var totalToPay = 0;

  cotations.forEach((element) => {
    element = element.trim();

    let firstDigit = element.match(/\d/);
    let index = element.indexOf(firstDigit);

    let coefficient = element.substring(0, index);
    let value = element.substring(index);
    let k = 0;

    if (value.includes("€")) {
      value = value.replace("€", "").trim();
      if (!Number.isNaN(parseFloat(value))) totalToPay += parseFloat(value);
    }

    else if (!Number.isNaN(parseInt(value))) {
      switch (coefficient) {
        case "B":
          k = 0.25;
          break;
        case "KB":
          k = 1.92;
          break;
        case "PB":
          k = 2.52;
          break;
        case "TB":
          k = 2.52;
          break;
        case "AMI":
          k = 3.15;
          break;
      }
      totalCotations += parseInt(value) * k;
    }
  });



  return [totalCotations, totalToPay];
}


function computeTotalCollectionPrice() {
  var totalCotations = 0;

  if (this.record.record_nabms.includes("9106")) {
    this.record.record_collection.push("AMI1,50");
    totalCotations += 4.73;
  }

  this.OUTSAMPLES.forEach((s) => {
    if (
      s.sample_matrix == "Serum" ||
      s.sample_matrix == "Sérum" ||
      s.sample_matrix == "Plasma" ||
      s.sample_matrix == "Sang"
    ) {
      if (!this.record.record_collection.includes("AMI1,50")) {
        this.record.record_collection.push("AMI1,50");
        totalCotations += 4.73;
      }
    } else if (
      (s.sample_matrix == "Naso-pharyngé" ||
        s.sample_matrix == "Salive") &&
      s.sample_type != "Neoteryx"
    ) {
      // IF PCR
      if (
        this.OUTSAMPLES[0].sample_analyses[0] == 1 ||
        this.OUTSAMPLES[0].sample_analyses[0] == 2
      ) {
        if (!this.record.record_collection.includes("KB3")) {
          this.record.record_collection.push("KB3");
          totalCotations += 5.76;
        }
      }

      // ELSE OTHER
      else {
        if (!this.record.record_collection.includes("KB5")) {
          this.record.record_collection.push("KB5");
          totalCotations += 9.6;
        }
      }
    }
  });

  return totalCotations;
}


function computeTotalBValue() {
  var totalB = 0;
  this.nabmTableAnalyses.forEach((n) => {
    n.analysis_b_price.split(";").forEach((element) => {
      if (element.includes("€")) return;
      let firstDigit = element.match(/\d/);
      let index = element.indexOf(firstDigit);
      let value = element.substring(index);
      if (!Number.isNaN(parseInt(value))) totalB += parseInt(value);
    });
  });
  return totalB;
}


function addMissingB(missingB) {
  const mappingB = {
    1: "9916", 2: "9917", 3: "9918", 4: "9919", 5: "9905", 6: "9920", 7: "9921", 8: "9922", 9: "9923",
    10: "9910", 11: "9924", 12: "9925", 13: "9926", 14: "9914", 15: "9915", 16: "9929", 17: "9927", 18: "9928"
  };

  return mappingB[missingB];
}


export default {
  methods: {
    extractAnalyses: extractAnalyses,
    addPreAnalyticPrice: addPreAnalyticPrice,
    extractNabmsCotations: extractNabmsCotations,
    computeTotalAnalyticalPrice: computeTotalAnalyticalPrice,
    computeTotalCollectionPrice: computeTotalCollectionPrice,
    computeTotalBValue: computeTotalBValue,
    addMissingB: addMissingB,
  },
}
