<template>
  <div id="app">
    <Backdrop v-if="$route.name != 'Login'" :hide="hide" />
    <SideMenu
      v-if="$route.name != 'Login'"
      :hide="hide"
      :hideEvent="handleHideEvent"
    />
    <vue-progress-bar style="height: 4px"></vue-progress-bar>
    <router-view />
  </div>
</template>

<script>
import Backdrop from "./components/Backdrop.vue";
import SideMenu from "./components/SideMenu.vue";
export default {
  name: "App",
  components: {
    Backdrop,
    SideMenu,
  },
  data: () => {
    return {
      hide: true,
    };
  },

  methods: {
    handleHideEvent() {
      this.hide = !this.hide;
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  /* zoom: 90%; */
}

.input-label {
  font-size: 15px;
  text-align: left;
  letter-spacing: 1px;
  margin: 0;
  padding-bottom: 5px;
  padding-left: 1px;
}

textarea,
input {
  width: 100%;
  border: 2px solid #cfcece;
  /* border-radius: 5px; */
  font: 15px Roboto !important;
  padding: 10px;
  outline: none;
  transition: border ease-in-out 0.2s;
}

textarea {
  min-height: 100px;
}

.small-textarea textarea {
  min-height: 50px !important;
}

textarea:focus,
input:focus {
  width: 100%;
  border: 2px solid #325c9a;
  /* border-radius: 8px; */
}

.formulate-input {
  text-transform: uppercase;
}

.formulate-input-element--submit button,
.button {
  text-transform: uppercase !important;
  padding: 8px 20px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  outline: none;
  border: none;
  background-color: #325c9a;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.formulate-input-element--submit button:hover,
.button:hover {
  background-color: #343472;
}

.formulate-input-errors {
  padding-left: 2px;
}

.formulate-input-error {
  color: rgb(196, 16, 16);
  font-size: 13px;
  letter-spacing: 1px;
  list-style-type: none;
  padding-top: 3px;
}

.clickable {
  cursor: pointer;
}

.open-button {
  border-radius: 10px;
  width: 35px;
  height: 35px;
  background-color: #325c9a;
  display: inline-block;
  transition: all ease-in-out 0.2s;
}

.open-button img {
  width: 35px;
  height: 35px;
  padding: 4px;
}

.open-button:hover {
  background-color: #343472;
}

.down-fab {
  right: 115px !important;
}

.add-fab {
  right: 65px !important;
}

.vgt-responsive {
  overflow-x: inherit !important;
}

.vs--searchable .vs__dropdown-toggle .vs__search {
  cursor: pointer !important;
}

.nav-link {
  color: #325c9a;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 4px 10px 5px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

input[type="checkbox"] {
  width: 15px;
}

#validate-button {
  text-transform: uppercase !important;
  padding: 8px 20px;
  border-radius: 20px !important;
  font-size: 14px !important;
  outline: none;
  border: none;
  background-color: #f44336;
  font-weight: bold;
  letter-spacing: 1px;
  color: white !important;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

#validate-button:hover {
  background-color: #b83228;
  text-decoration: none !important;
}

label {
  font-size: 14px;
}

td {
  vertical-align: middle !important;
}
</style>
